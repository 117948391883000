import './App.css';
import { useState } from 'react';

function App() {

  const [mgSource, setMGSource] = useState("");
  const [hsSource, setHSSource] = useState("");

  const copy = () => {
    navigator.clipboard.writeText(hsSource).then(function() {
      alert("Hubspot template copied to clipboard!");
    }, function(err) {
      alert("Something went wrong, use CTRL+A & CTRL+C to manually copy source code to clipboard...");
    });    
  }

  const convert = () => {

    let newSource = mgSource.replace(/(\n)/gi, '|NL|');
    newSource = newSource.replace(/(\s{2,})/gi, '|NS|');

    let regex = /(\${Recipient.TelemetrySecret}|\${\|NL\|\|NS\|Recipient.TelemetrySecret\|NL\|\|NS\|})/gi;
    newSource = newSource.replace(regex, '{{contact.custom_prop_stylist_telemetry_secret}}');
    newSource = newSource.replace(/\|NS\|/gi, ' ');
    newSource = newSource.replace(/\|NL\|/gi, '\n');
    newSource = newSource.replace("© The Stylist Group Ltd</div></td></tr>", '© The Stylist Group Ltd</div></td></tr><tr><td align="center" style="padding:0;padding-left:30px;padding-right:30px;padding-bottom:35px"><address><div class="brasilia" style="font-family:futura-pt,sans-serif;font-weight:400;font-size:16px;line-height:23px;color:#262728;padding-bottom:10px">{{site_settings.company_name}}, {{site_settings.company_street_address_1}}, {{site_settings.company_city}}, {{site_settings.company_state}}, {{site_settings.company_zip}}, {{site_settings.company_country}}</div></address><a href="{{ unsubscribe_link }}" target="_blank" style="display:none">Hidden Unsubscribe</a></td></tr>');

    setHSSource(newSource);

  }

  return (
    <div className='app'>
      <div className="container">

          <div className='row'>
            <div className='col'>
              <h1>You no longer need to do that.</h1>
            </div>
          </div>

          {
            /*
          <div className='row p-2'>
            <div className='col'>        
              <p>Paste MG email template here:</p>
              <textarea className="form-control" cols={80} rows={10} onChange={(e) => setMGSource(e.currentTarget.value)} />
            </div>
          </div>

          <div className='row p-2'>
            <div className='col'>
              <button type="button" onClick={convert} className="btn btn-success p-2">Convert to Hubspot template</button>
            </div>
          </div>

          <div className='row p-2'>
            <div className='col'>
              <textarea className="form-control" cols={80} rows={10} value={hsSource} readOnly />
            </div>
          </div>

          <div className='row p-2'>
            <div className='col'>
              <button type="button" onClick={copy} className="btn btn-primary p-2">Click to copy HS template</button>
            </div>
          </div>            
            */
          }

          <div className='row p-2'>
            <div className='col'>
              <span>v1.0.1</span>
            </div>
          </div>

        </div>
      </div>
      
  );
}

export default App;
